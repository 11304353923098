:root {
  --color-primary: #1971ff;
  --color-primary-85: #3984fd;
  --color-primary-70: #5a97fc;
  --color-primary-45: #8fb8f9;
  --color-primary-25: #bbd2f7;
  --color-primary-5: #e6edf5;
  --color-primary-hover: var(--color-primary-85);

  /* 成功颜色 */
  --color-success: #07c160;

  /* danger 错误基础色 */
  --color-danger: #f71f22;
  --color-danger-font: #bf3e36;
  --color-danger-font-1: #fc2249;
  --color-danger-font-2: #ffe8ec;

  /* warn 警告基础色 */
  --color-warning: #ff9c00;
  --color-warning-1: #fff1e5;

  /* info 提示基础色 */
  --color-info: #00c6ff;

  /* 黄色 */
  --color-other-yellow: #fbd322;
  /* 紫色 */
  --color-other-purple: #7022ff;
  /* 蓝色 */
  --color-other-blur: #004da0;

  /* font */
  /* 字体主色调 */
  --color-font-black: #19191a;
  /* 字体主色调的85% ..... */
  --color-font-black-85: #3b3b3c;
  --color-font-black-80: #474748;
  --color-font-black-70: #5e5e5e;
  --color-font-black-60: #757576;
  --color-font-black-45: #979798;
  --color-font-black-40: #a3a3a3;
  --color-font-black-25: #c5c5c6;
  --color-font-white: var(--color-white);

  /* background */
  --color-background: #f5f5f5;
  --color-background-2: rgba(25, 113, 255, 0.05);
  --color-background-3: #f5f6f7;
  --color-background-4: #f3f8ff;
  --color-background-5: #4c4c4d;
  --color-background-6: #b1b1b3;
  --color-background-7: #f5f5f7;
  --color-background-8: rgba(255, 255, 255, 0.92);
  --color-background-9: rgba(25, 25, 26, 0.7);
  --color-background-10: rgba(25, 25, 26, 0.05);
  --color-background-11: #fafbfc;
  --color-background-12: #a80000;
  --color-background-13: #646566;
  --color-background-14: rgba(0, 0, 0, 0.2);
  --color-background-15: #f7f8fa;
  --color-background-16: #d8d8d8;
  --color-white: #ffffff;
  --color-transparent: transparent;

  /* 按钮字体  */
  --color-btn: #7e7f80;
  /* 文字按钮 */
  --color-btn-text: #b3b3b3;

  /* border边框 */
  --color-border: #dddddd;
  --color-border-1: var(--color-background-13);
  --color-border-2: var(--color-background-16);
  --color-border-3: #ebeced;
  --color-border-4: #ebedf0;
  /* 细线的border */
  --color-fine-border: #e1e1e6;
  /* 模块之间的间距 */
  --color-interval: #f6f6fb;
  /* 禁用颜色 */
  --color-disabled: var(--color-border-3);
  /* box-shadow阴影 */
  --color-box-shadow: 1px 1px 3px 0px var(--color-border-1);
  --color-box-shadow-2: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  --color-box-shadow-3: 0px 1px 3px 0px rgba(153, 153, 153, 0.05);
  --color-box-shadow-4: 0px 1px 3px 0px var(--color-background-14);
  /* 标题 */
  --color-title: #252526;
  /* 标题2 微信 */
  --color-title-2: #030303;

  /* 副标题 */
  --color-subtitle: #89898c;
  /* 副标题2 微信 */
  --color-subtitle-2: #999999;

  /* 提示信息 */
  --color-placeholder: #bbbbbf;

  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-22: 22px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-12: 12px;
  --font-size-10: 10px;

  --border-radius: 5px;

  --backgroud-description: rgba(25, 113, 255, 0.15);

  --finish-border: var(--color-title);

  --input-height: 40px;

  --service-btn-background: linear-gradient(180deg, #5a71df 0%, #2a72f5 100%);

  /* Transition variables
-------------------------- */
  --all-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  --fade-transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
  --fade-linear-transition: opacity 200ms linear;
  --md-fade-transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
  --border-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  --color-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  --text-shadow: 1px 1px 3px var(--color-border-1);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  line-height: 1.5;
}

body {
  min-width: 1440px;
  overflow: auto;
}

@media (prefers-color-scheme: dark) {
  html,
  body {
    color: var(--color-font-black);
  }
}

/* Safari 15+在加载时显示灰色边框 */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px);
  }
}

.visually_hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.overflow-hidden {
  overflow: hidden;
}